module.exports = {
  "violin": {
    "A#3": "Violin/violin-g3.wav",
    "A#4": "Violin/violin-e4.wav",
    "A#5": "Violin/violin-e5.wav",
    "A#6": "Violin/violin-e6.wav",
    "C#4": "Violin/violin-e4.wav",
    "C#5": "Violin/violin-e5.wav",
    "C#6": "Violin/violin-e6.wav",
    "C#7": "Violin/violin-e4.wav",
    "E4": "Violin/violin-e4.wav",
    "E5": "Violin/violin-e5.wav",
    "E6": "Violin/violin-e6.wav",
    "G3": "Violin/violin-g3.wav",
    "G4": "Violin/violin-g4.wav",
    "G5": "Violin/violin-g5.wav",
    "G6": "Violin/violin-g6.wav"
  },
  "string ensemble 1": {
    "G3": "1st Violins/1st-violins-sus-g3.wav",
    "A#3": "1st Violins/1st-violins-sus-g3.wav",
    "C#4": "1st Violins/1st-violins-sus-g4.wav",
    "E4": "1st Violins/1st-violins-sus-e4.wav",
    "G4": "1st Violins/1st-violins-sus-g4.wav",
    "A#4": "1st Violins/1st-violins-sus-g4.wav",
    "C#5": "1st Violins/1st-violins-sus-g5.wav",
    "E5": "1st Violins/1st-violins-sus-e5.wav",
    "G5": "1st Violins/1st-violins-sus-g5.wav",
    "A#5": "1st Violins/1st-violins-sus-g5.wav",
    "C#6": "1st Violins/1st-violins-sus-g6.wav",
    "E6": "1st Violins/1st-violins-sus-e6.wav",
    "G6": "1st Violins/1st-violins-sus-g6.wav",
    "A#6": "1st Violins/1st-violins-sus-g6.wav"
  },
  "viola": {
    "A3": "Violas/violas-sus-a3.wav",
    "A4": "Violas/violas-sus-a4.wav",
    "A5": "Violas/violas-sus-a5.wav",
    "C3": "Violas/violas-sus-c3.wav",
    "C4": "Violas/violas-sus-c4.wav",
    "C6": "Violas/violas-sus-c6.wav",
    "D#3": "Violas/violas-sus-a3.wav",
    "D#4": "Violas/violas-sus-c4.wav",
    "F#3": "Violas/violas-sus-a3.wav",
    "F#4": "Violas/violas-sus-c4.wav",
    "F#5": "Violas/violas-sus-c5.wav"
  },
  "cello": {
    "A2": "Celli/celli-sus-a2.wav",
    "A3": "Celli/celli-sus-a3.wav",
    "A4": "Celli/celli-sus-a4.wav",
    "C2": "Celli/celli-sus-c2.wav",
    "C3": "Celli/celli-sus-c3.wav",
    "C4": "Celli/celli-sus-c4.wav",
    "C5": "Celli/celli-sus-c5.wav",
    "D#2": "Celli/celli-sus-c2.wav",
    "D#3": "Celli/celli-sus-c3.wav",
    "D#4": "Celli/celli-sus-c4.wav",
    "F#2": "Celli/celli-sus-a2.wav",
    "F#3": "Celli/celli-sus-a3.wav",
    "F#4": "Celli/celli-sus-a4.wav"
  },
  "contrabass": {
    "A1": "Basses/basses-sus-a1.wav",
    "A2": "Basses/basses-sus-a2.wav",
    "A3": "Basses/basses-sus-a3.wav",
    "C1": "Basses/basses-sus-c1.wav",
    "C2": "Basses/basses-sus-c2.wav",
    "C3": "Basses/basses-sus-c3.wav",
    "C4": "Basses/basses-sus-c4.wav",
    "D#1": "Basses/basses-sus-a1.wav",
    "D#2": "Basses/basses-sus-c2.wav",
    "D#3": "Basses/basses-sus-a3.wav",
    "F#1": "Basses/basses-sus-c1.wav",
    "F#2": "Basses/basses-sus-a2.wav",
    "F#3": "Basses/basses-sus-c3.wav"
  }
}
;