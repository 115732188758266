module.exports = {
  "header": {
    "PPQ": 480,
    "bpm": 130.00013000013,
    "timeSignature": [
      4,
      4
    ],
    "name": "Eine Kleine Nachtmusik"
  },
  "startTime": 0,
  "duration": 42.461496000000054,
  "tracks": [
    {
      "startTime": 0,
      "duration": 42.46149599999999,
      "length": 145,
      "notes": [
        {
          "name": "G5",
          "midi": 79,
          "time": 0,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999997
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 0,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999997
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 0,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999997
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 0.6923069999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 0.9230759999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.4615379999999999
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 1.6153829999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 1.8461519999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999978
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 2.0769209999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 2.3076899999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "B5",
          "midi": 83,
          "time": 2.5384589999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "D6",
          "midi": 86,
          "time": 2.7692279999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "C6",
          "midi": 84,
          "time": 3.6923039999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999956
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 4.384610999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "C6",
          "midi": 84,
          "time": 4.615379999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 5.307686999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "C6",
          "midi": 84,
          "time": 5.538455999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 5.769224999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 5.9999939999999965,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 6.230762999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 6.461531999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 7.384607999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 7.846145999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.6923070000000004
        },
        {
          "name": "B5",
          "midi": 83,
          "time": 8.538452999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 8.769221999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 8.999990999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 9.230759999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 9.461528999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 9.692297999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.6923069999999996
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 10.384604999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C6",
          "midi": 84,
          "time": 10.615373999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 10.846142999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 11.076911999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 11.307680999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 11.53845,
          "velocity": 0.9921259842519685,
          "duration": 0.6923069999999996
        },
        {
          "name": "B5",
          "midi": 83,
          "time": 12.230756999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 12.461526,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 12.692295,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 12.923064,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 13.153833,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 13.384602000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.6923069999999996
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 14.076909,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C6",
          "midi": 84,
          "time": 14.307678000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 14.538447000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 14.769216000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 14.999985000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 15.230754000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999933
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 15.346138500000002,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999933
        },
        {
          "name": "E5",
          "midi": 76,
          "time": 15.461523000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999933
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 15.5769075,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999933
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 15.692292,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 15.923061,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B5",
          "midi": 83,
          "time": 16.15383,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 16.2692145,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 16.384599,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 16.499983500000003,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "B5",
          "midi": 83,
          "time": 16.615368000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B5",
          "midi": 83,
          "time": 16.846137000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D6",
          "midi": 86,
          "time": 17.076906,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "C6",
          "midi": 84,
          "time": 17.192290500000002,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "B5",
          "midi": 83,
          "time": 17.307675000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "C6",
          "midi": 84,
          "time": 17.423059500000004,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "D6",
          "midi": 86,
          "time": 17.538444000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 18.461520000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "E5",
          "midi": 76,
          "time": 19.384596000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 20.307672000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 20.423056500000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 20.769210000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 21.230748000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 21.346132500000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 21.692286000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 22.153824000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 22.26920850000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 22.615362000000008,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 23.07690000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "F#4",
          "midi": 66,
          "time": 23.307669000000008,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "E4",
          "midi": 64,
          "time": 23.538438000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "F#4",
          "midi": 66,
          "time": 23.769207000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 23.999976000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 24.461514,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 24.923052,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 25.846128,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "E5",
          "midi": 76,
          "time": 26.769204,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 27.692279999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 27.923048999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 28.153817999999994,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 28.384586999999993,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 28.61535599999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 28.84612499999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 29.07689399999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 29.307662999999987,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 29.538431999999986,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 29.769200999999985,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 29.999969999999983,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 30.230738999999982,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 30.46150799999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "F#4",
          "midi": 66,
          "time": 30.69227699999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "E4",
          "midi": 64,
          "time": 30.923045999999978,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "F#4",
          "midi": 66,
          "time": 31.153814999999977,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 31.384583999999975,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 31.384583999999975,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 31.384583999999975,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 32.076890999999975,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 32.30765999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 32.53842899999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 32.76919799999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 32.999966999999984,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 33.230735999999986,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 33.46150499999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 33.69227399999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B5",
          "midi": 83,
          "time": 33.92304299999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D6",
          "midi": 86,
          "time": 34.153811999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 34.61534999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 34.61534999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 34.61534999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 35.07688799999999,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 35.07688799999999,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 35.07688799999999,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 35.76919499999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 35.99996399999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 36.230732999999994,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 36.461501999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 36.692271,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 36.92304,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 37.153809,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 37.384578000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B5",
          "midi": 83,
          "time": 37.61534700000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D6",
          "midi": 86,
          "time": 37.84611600000001,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 38.30765400000001,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 38.30765400000001,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 38.30765400000001,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 38.769192000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 38.769192000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 38.769192000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 39.692268,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 39.692268,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 39.692268,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 40.61534399999999,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 40.61534399999999,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 40.61534399999999,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 41.07688199999999,
          "velocity": 0.9921259842519685,
          "duration": 0.3461534999999998
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 41.07688199999999,
          "velocity": 0.9921259842519685,
          "duration": 0.3461534999999998
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 41.42303549999999,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999756
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 41.42303549999999,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999756
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 41.53841999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.9230760000000018
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 41.53841999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.9230760000000018
        }
      ],
      "controlChanges": {},
      "id": 0,
      "name": "Eine Kleine Nachtmusik",
      "instrumentNumber": 0,
      "instrument": "violin",
      "instrumentFamily": "strings",
      "channelNumber": 0,
      "isPercussion": false
    },
    {
      "startTime": 0,
      "duration": 42.46149600000002,
      "length": 198,
      "notes": [
        {
          "name": "G5",
          "midi": 79,
          "time": 0,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999995
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 0,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999995
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 0,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999995
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 0.6923069999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 0.9230759999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.4615379999999999
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 1.6153829999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 1.8461519999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999978
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 2.0769209999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 2.3076899999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "B5",
          "midi": 83,
          "time": 2.5384589999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "D6",
          "midi": 86,
          "time": 2.7692279999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "C6",
          "midi": 84,
          "time": 3.6923039999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999956
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 4.384610999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "C6",
          "midi": 84,
          "time": 4.615379999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 5.307686999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "C6",
          "midi": 84,
          "time": 5.538455999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 5.769224999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "F#5",
          "midi": 78,
          "time": 5.9999939999999965,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "A5",
          "midi": 81,
          "time": 6.230762999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 6.461531999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 7.384607999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 7.384607999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 7.615376999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 7.615376999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 7.846145999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 7.846145999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 8.076914999999994,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 8.076914999999994,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 8.307683999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 8.307683999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 8.538452999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 8.538452999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 8.769221999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 8.769221999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 8.999990999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 8.999990999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 9.230759999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 9.230759999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 9.461528999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 9.461528999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 9.692297999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 9.692297999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 9.923066999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 9.923066999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 10.153835999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 10.153835999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 10.384604999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 10.384604999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 10.615374,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 10.615374,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 10.846143,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 10.846143,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 11.076912,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 11.076912,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 11.307681,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 11.307681,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 11.538450000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 11.538450000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 11.769219000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 11.769219000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 11.999988000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 11.999988000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 12.230757000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 12.230757000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 12.461526000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 12.461526000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 12.692295000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 12.692295000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 12.923064000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 12.923064000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 13.153833000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 13.153833000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 13.384602000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 13.384602000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 13.615371000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 13.615371000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 13.846140000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 13.846140000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 14.076909000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 14.076909000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 14.307678000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 14.307678000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 14.538447000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 14.538447000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 14.769216000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.4615379999999991
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 14.769216000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.4615379999999991
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 15.230754000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 15.461523000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 15.692292000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 15.923061000000008,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 16.153830000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 16.384599000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 16.499983500000006,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 16.615368000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 16.846137000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "F#4",
          "midi": 66,
          "time": 17.076906000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "F#4",
          "midi": 66,
          "time": 17.307675000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 17.538444000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 18.461520000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 19.384596000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 20.307672,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 20.4230565,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 20.76921,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 21.230748000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 21.346132500000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 21.692286000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "E4",
          "midi": 64,
          "time": 22.153824000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "E4",
          "midi": 64,
          "time": 22.615362000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 23.076900000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 23.538438000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 23.999976000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 24.461514000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 24.923052000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 25.846128000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 26.769204000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 27.69228,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 27.923049,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 28.153817999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 28.384586999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 28.615355999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 28.846124999999994,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 29.076893999999992,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 29.30766299999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "E4",
          "midi": 64,
          "time": 29.53843199999999,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "E4",
          "midi": 64,
          "time": 29.99996999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "E4",
          "midi": 64,
          "time": 30.23073899999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 30.461507999999988,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 30.92304599999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 31.153814999999987,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 31.384583999999986,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 31.384583999999986,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 31.615352999999985,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 31.615352999999985,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 31.846121999999983,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 31.846121999999983,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 32.07689099999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 32.07689099999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 32.307659999999984,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 32.307659999999984,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 32.53842899999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 32.53842899999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 32.76919799999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 32.76919799999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 32.99996699999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 32.99996699999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 33.23073599999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 33.23073599999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 33.461504999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 33.461504999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 33.692274,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 33.692274,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 33.923043,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 33.923043,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 34.153812,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 34.153812,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 34.61535000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 34.61535000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 35.076888000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 35.076888000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 35.307657000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 35.307657000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 35.53842600000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 35.53842600000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 35.76919500000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 35.76919500000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 35.99996400000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 35.99996400000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 36.230733000000015,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 36.230733000000015,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 36.46150200000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 36.46150200000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 36.69227100000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 36.69227100000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 36.92304000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 36.92304000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 37.153809000000024,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 37.153809000000024,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 37.384578000000026,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 37.384578000000026,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 37.61534700000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 37.61534700000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 37.84611600000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 37.84611600000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 38.307654000000035,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 38.307654000000035,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 38.76919200000003,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 38.76919200000003,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 39.69226800000003,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 39.69226800000003,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G5",
          "midi": 79,
          "time": 40.61534400000002,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 40.61534400000002,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 40.61534400000002,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 41.07688200000002,
          "velocity": 0.9921259842519685,
          "duration": 0.3461534999999998
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 41.07688200000002,
          "velocity": 0.9921259842519685,
          "duration": 0.3461534999999998
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 41.42303550000002,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999756
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 41.42303550000002,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999756
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 41.538420000000016,
          "velocity": 0.9921259842519685,
          "duration": 0.9230760000000018
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 41.538420000000016,
          "velocity": 0.9921259842519685,
          "duration": 0.9230760000000018
        }
      ],
      "controlChanges": {},
      "id": 1,
      "instrumentNumber": 40,
      "instrument": "string ensemble 1",
      "instrumentFamily": "strings",
      "channelNumber": 1,
      "isPercussion": false
    },
    {
      "startTime": 0,
      "duration": 42.461496000000054,
      "length": 120,
      "notes": [
        {
          "name": "G4",
          "midi": 67,
          "time": 0,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999995
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 0.6923069999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 0.9230759999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.4615379999999999
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 1.6153829999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 1.8461519999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999978
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 2.0769209999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 2.3076899999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 2.5384589999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 2.7692279999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 3.6923039999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999956
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 4.384610999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 4.615379999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 5.307686999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 5.538455999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 5.769224999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "F#4",
          "midi": 66,
          "time": 5.9999939999999965,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 6.230762999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 6.461531999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 7.384607999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 7.615376999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 7.846145999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 8.076914999999994,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 8.307683999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 8.538452999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 8.769221999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 8.999990999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 9.230759999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 9.461528999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 9.692297999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 9.923066999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 10.153835999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 10.384604999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "F#4",
          "midi": 66,
          "time": 10.615374,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 10.846143,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 11.076912,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 11.307681,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 11.538450000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 11.769219000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 11.999988000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 12.230757000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 12.461526000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 12.692295000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 12.923064000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 13.153833000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 13.384602000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 13.615371000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 13.846140000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "C5",
          "midi": 72,
          "time": 14.076909000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "F#4",
          "midi": 66,
          "time": 14.307678000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A4",
          "midi": 69,
          "time": 14.538447000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 14.769216000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.4615379999999991
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 15.230754000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 15.461523000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 15.692292000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 15.923061000000008,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 16.153830000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 16.384599000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 16.499983500000006,
          "velocity": 0.9921259842519685,
          "duration": 0.11538450000000111
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 16.615368000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 17.076906000000008,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 17.307675000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 17.538444000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 17.769213000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "F#3",
          "midi": 54,
          "time": 20.307672000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 21.230748000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 22.153824,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 22.615362,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 23.076900000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 23.538438000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 23.999976000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 24.461514,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 24.923052,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "F#4",
          "midi": 66,
          "time": 27.69228,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 28.615356,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 29.538431999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 29.999969999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 30.230738999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 30.461507999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 30.923045999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 31.153814999999994,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 31.384583999999993,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 31.615352999999992,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 31.84612199999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 32.07689099999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 32.30765999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 32.538428999999994,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 32.769197999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 32.999967,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 33.230736,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 33.461505,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 33.692274000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 33.92304300000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 34.15381200000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 34.615350000000014,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 35.07688800000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 35.30765700000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 35.538426000000015,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 35.76919500000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 35.99996400000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 36.23073300000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 36.461502000000024,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 36.69227100000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 36.92304000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 37.15380900000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 37.38457800000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 37.615347000000035,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 37.84611600000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 38.30765400000004,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 38.76919200000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 38.99996100000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 39.230730000000044,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 39.461499000000046,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 39.69226800000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 39.92303700000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D5",
          "midi": 74,
          "time": 40.15380600000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B4",
          "midi": 71,
          "time": 40.384575000000055,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G4",
          "midi": 67,
          "time": 40.61534400000006,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 41.076882000000055,
          "velocity": 0.9921259842519685,
          "duration": 0.3461534999999998
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 41.423035500000054,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999756
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 41.53842000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.9230760000000018
        }
      ],
      "controlChanges": {},
      "id": 2,
      "instrumentNumber": 41,
      "instrument": "viola",
      "instrumentFamily": "strings",
      "channelNumber": 2,
      "isPercussion": false
    },
    {
      "startTime": 0,
      "duration": 41.99995800000007,
      "length": 122,
      "notes": [
        {
          "name": "G3",
          "midi": 55,
          "time": 0,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999995
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 0.6923069999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 0.9230759999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.4615379999999999
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 1.6153829999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 1.8461519999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999978
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 2.0769209999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 2.3076899999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 2.5384589999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 2.7692279999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 3.6923039999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999956
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 4.384610999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 4.615379999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 5.307686999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "C4",
          "midi": 60,
          "time": 5.538455999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 5.769224999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "F#3",
          "midi": 54,
          "time": 5.9999939999999965,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 6.230762999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 6.461531999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 7.384607999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 7.615376999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 7.846145999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 8.076914999999994,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 8.307683999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 8.538452999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 8.769221999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 8.999990999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 9.230759999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 9.461528999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 9.692297999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 9.923066999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 10.153835999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 10.384604999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 10.615374,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 10.846143,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 11.076912,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 11.307681,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 11.538450000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 11.769219000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 11.999988000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 12.230757000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 12.461526000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 12.692295000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 12.923064000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 13.153833000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 13.384602000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 13.615371000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 13.846140000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 14.076909000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 14.307678000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 14.538447000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 14.769216000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 14.999985000000008,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 15.230754000000008,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 15.461523000000009,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 15.692292000000009,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 15.92306100000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "F#3",
          "midi": 54,
          "time": 16.15383000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "F#3",
          "midi": 54,
          "time": 16.38459900000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 16.615368000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 16.846137000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 17.076906000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A3",
          "midi": 57,
          "time": 17.307675000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 17.538444000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 20.307672000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "E3",
          "midi": 52,
          "time": 21.230748000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "C3",
          "midi": 48,
          "time": 22.153824,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "C3",
          "midi": 48,
          "time": 22.615362,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 23.076900000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 23.538438000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "B2",
          "midi": 47,
          "time": 23.999976000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 24.461514,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 24.923052,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 27.69228,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "E3",
          "midi": 52,
          "time": 28.615356,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "C3",
          "midi": 48,
          "time": 29.538431999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "C3",
          "midi": 48,
          "time": 29.999969999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 30.461508,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 30.923046,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 31.384584,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 31.615353,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 31.846121999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 32.076890999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 32.30766,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 32.538429,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 32.769198,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 32.999967000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 33.23073600000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 33.46150500000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 33.69227400000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 33.923043000000014,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 34.153812000000016,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 34.38458100000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 34.61535000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 34.84611900000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 35.076888000000025,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 35.30765700000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 35.53842600000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 35.76919500000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 35.999964000000034,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 36.230733000000036,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 36.46150200000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 36.69227100000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 36.92304000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 37.153809000000045,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 37.38457800000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 37.61534700000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 37.84611600000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 38.076885000000054,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 38.307654000000056,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 38.53842300000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 38.76919200000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B2",
          "midi": 47,
          "time": 38.99996100000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 39.230730000000065,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 39.46149900000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 39.69226800000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 39.92303700000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D4",
          "midi": 62,
          "time": 40.153806000000074,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B3",
          "midi": 59,
          "time": 40.384575000000076,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G3",
          "midi": 55,
          "time": 40.61534400000008,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 41.076882000000076,
          "velocity": 0.9921259842519685,
          "duration": 0.3461534999999998
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 41.423035500000076,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999756
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 41.53842000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        }
      ],
      "controlChanges": {},
      "id": 3,
      "instrumentNumber": 42,
      "instrument": "cello",
      "instrumentFamily": "strings",
      "channelNumber": 3,
      "isPercussion": false
    },
    {
      "startTime": 0,
      "duration": 41.99995800000007,
      "length": 116,
      "notes": [
        {
          "name": "G2",
          "midi": 43,
          "time": 0,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999995
        },
        {
          "name": "D2",
          "midi": 38,
          "time": 0.6923069999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 0.9230759999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.4615379999999999
        },
        {
          "name": "D2",
          "midi": 38,
          "time": 1.6153829999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 1.8461519999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999978
        },
        {
          "name": "D2",
          "midi": 38,
          "time": 2.0769209999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 2.3076899999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "B2",
          "midi": 47,
          "time": 2.5384589999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.230769
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 2.7692279999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "C3",
          "midi": 48,
          "time": 3.6923039999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999956
        },
        {
          "name": "A2",
          "midi": 45,
          "time": 4.384610999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "C3",
          "midi": 48,
          "time": 4.615379999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "A2",
          "midi": 45,
          "time": 5.307686999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "C3",
          "midi": 48,
          "time": 5.538455999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "A2",
          "midi": 45,
          "time": 5.769224999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "F#2",
          "midi": 42,
          "time": 5.9999939999999965,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "A2",
          "midi": 45,
          "time": 6.230762999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "D2",
          "midi": 38,
          "time": 6.461531999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.461538
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 7.384607999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 7.615376999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 7.846145999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999956
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 8.076914999999994,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 8.307683999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 8.538452999999995,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 8.769221999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 8.999990999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 9.230759999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 9.461528999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 9.692297999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 9.923066999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 10.153835999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 10.384604999999999,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 10.615374,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 10.846143,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 11.076912,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 11.307681,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 11.538450000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 11.769219000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 11.999988000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 12.230757000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 12.461526000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 12.692295000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 12.923064000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 13.153833000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 13.384602000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 13.615371000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 13.846140000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 14.076909000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 14.307678000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 14.538447000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 14.769216000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 14.999985000000008,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A2",
          "midi": 45,
          "time": 15.230754000000008,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "A2",
          "midi": 45,
          "time": 15.461523000000009,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B2",
          "midi": 47,
          "time": 15.692292000000009,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "B2",
          "midi": 47,
          "time": 15.92306100000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000045
        },
        {
          "name": "F#2",
          "midi": 42,
          "time": 16.15383000000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "F#2",
          "midi": 42,
          "time": 16.38459900000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 16.615368000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 16.846137000000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A2",
          "midi": 45,
          "time": 17.076906000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "A2",
          "midi": 45,
          "time": 17.307675000000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "B2",
          "midi": 47,
          "time": 17.538444000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "B1",
          "midi": 35,
          "time": 23.999976000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "D2",
          "midi": 38,
          "time": 24.461514,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 24.923052,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D2",
          "midi": 38,
          "time": 27.69228,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "E2",
          "midi": 40,
          "time": 28.615356,
          "velocity": 0.9921259842519685,
          "duration": 0.9230759999999982
        },
        {
          "name": "C2",
          "midi": 36,
          "time": 29.538431999999997,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "C2",
          "midi": 36,
          "time": 29.999969999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D2",
          "midi": 38,
          "time": 30.461508,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "D2",
          "midi": 38,
          "time": 30.923046,
          "velocity": 0.9921259842519685,
          "duration": 0.4615380000000009
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 31.384584,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 31.615353,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 31.846121999999998,
          "velocity": 0.9921259842519685,
          "duration": 0.23076899999999867
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 32.076890999999996,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 32.30766,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 32.538429,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 32.769198,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 32.999967000000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 33.23073600000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 33.46150500000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 33.69227400000001,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 33.923043000000014,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 34.153812000000016,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 34.38458100000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 34.61535000000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 34.84611900000002,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 35.076888000000025,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 35.30765700000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 35.53842600000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 35.76919500000003,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 35.999964000000034,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 36.230733000000036,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 36.46150200000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 36.69227100000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 36.92304000000004,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 37.153809000000045,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 37.38457800000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 37.61534700000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 37.84611600000005,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 38.076885000000054,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 38.307654000000056,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 38.53842300000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 38.76919200000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B1",
          "midi": 35,
          "time": 38.99996100000006,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D2",
          "midi": 38,
          "time": 39.230730000000065,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 39.46149900000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B2",
          "midi": 47,
          "time": 39.69226800000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 39.92303700000007,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "D3",
          "midi": 50,
          "time": 40.153806000000074,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "B2",
          "midi": 47,
          "time": 40.384575000000076,
          "velocity": 0.9921259842519685,
          "duration": 0.23076900000000222
        },
        {
          "name": "G2",
          "midi": 43,
          "time": 40.61534400000008,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 41.076882000000076,
          "velocity": 0.9921259842519685,
          "duration": 0.3461534999999998
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 41.423035500000076,
          "velocity": 0.9921259842519685,
          "duration": 0.11538449999999756
        },
        {
          "name": "G1",
          "midi": 31,
          "time": 41.53842000000007,
          "velocity": 0.9921259842519685,
          "duration": 0.46153799999999734
        }
      ],
      "controlChanges": {},
      "id": 4,
      "instrumentNumber": 43,
      "instrument": "contrabass",
      "instrumentFamily": "strings",
      "channelNumber": 4,
      "isPercussion": false
    }
  ]
};